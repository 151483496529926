import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
/* eslint-disable */
export let routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index'),
    redirect: '/home',
    meta: {
      requiresAuth: true,
      noCache: false,
      roles:[1,2,3]
    },
    children: [
      {
        path: 'home',
        component: () => import('@/views/home'),
        meta: {
          title: 'tIndex',
          requiresAuth: true,
          noCache: false,
          roles:[1,2,3]
        }
      },
      {
        path: 'member',
        component: () => import('@/components/animate'),
        meta: {
          title:'tMember',
          requiresAuth: true,
          noCache: false,
          roles:[1,2]
        },
        children:[
          {
            path:'',
            component: () => import('@/views/member'),
            name:'member',
            meta:{
              requiresAuth: true,
              noCache: false,
              roles:[1,2]
            },
          },
          {
            path:'detail',
            component: () => import('@/views/member/detail'),
            name:'memberDetail',
            meta:{
              title:'tMemberDetail',
              requiresAuth: true,
              noCache: false,
              roles:[1,2]
            }
          },
          {
            path:':userId/score',
            component: () => import('@/views/member/score'),
            name:'score',
            meta:{
              title:'tScore',
              requiresAuth: true,
              noCache: false,
              roles:[1,2]
            }
          },
          {
            path:':userId/finishhistory',
            component: () => import('@/views/member/finishhistory'),
            name:'finishhistory',
            meta:{
              title:'tFinishHistory',
              requiresAuth: true,
              noCache: false,
              roles:[1,2]
            }
          }
        ]
      },
      {
        path: 'account',
        component: () => import('@/components/animate'),
        meta: {
          title:'tAccount',
          requiresAuth: true,
          noCache: false,
          roles:[1]
        },
        children:[
          {
            path:'',
            component: () => import('@/views/account'),
            name:'account',
            meta:{
              requiresAuth: true,
              noCache: false,
              roles:[1]
            },
          },
          {
            path:'edit',
            component: () => import('@/views/account/edit'),
            name:'accountEdit',
            meta:{
              title:'tAccountEdit',
              requiresAuth: true,
              noCache: false,
              roles:[1]
            }
          }
        ]
      },
      {
        path: 'serviceTerm',
        component: () => import('@/components/animate'),
        meta: {
          title:'tServiceTerm',
          requiresAuth: true,
          noCache: false,
          roles:[1,2]
        },
        children:[
          {
            path:'',
            component: () => import('@/views/serviceTerm'),
            name:'serviceTerm',
            meta:{
              requiresAuth: true,
              noCache: false,
              roles:[1,2]
            },
          },
          {
            path:'edit',
            component: () => import('@/views/serviceTerm/edit'),
            name:'serviceTermEdit',
            meta:{
              title:'tServiceTermEdit',
              requiresAuth: true,
              noCache: false,
              roles:[1,2]
            }
          }
        ]
      },
      {
        path: 'customerCenter',
        component: () => import('@/components/animate'),
        meta: {
          title:'tCustomerCenter',
          requiresAuth: true,
          noCache: false,
          roles:[1,2,3]
        },
        redirect:'/customerCenter/notice',
        children:[
          {
            path:'notice',
            component: () => import('@/components/animate'),
            meta:{
              title:'tNotice',
              requiresAuth: true,
              noCache: false,
              roles:[1,2]
            },
            children:[
              {
                path:'',
                component: () => import('@/views/notice'),
                name:'notice',
                meta:{
                  requiresAuth: true,
                  noCache: false,
                  roles:[1,2]
                },
              },
              {
                path:'edit',
                component: () => import('@/views/notice/edit'),
                name:'noticeEdit',
                meta:{
                  title:'tNoticeEdit',
                  requiresAuth: true,
                  noCache: false,
                  roles:[1,2]
                },
              },
            ]
          },
          {
            path:'faq',
            component: () => import('@/components/animate'),
            meta:{
              title:'tFAQ',
              requiresAuth: true,
              noCache: false,
              roles:[1,2]
            },
            children:[
              {
                path:'',
                component: () => import('@/views/faq'),
                name:'faq',
                meta:{
                  requiresAuth: true,
                  noCache: false,
                  roles:[1,2]
                },
              },
              {
                path:'edit',
                component: () => import('@/views/faq/edit'),
                name:'faqEdit',
                meta:{
                  title:'tFAQEdit',
                  requiresAuth: true,
                  noCache: false,
                  roles:[1,2]
                },
              },
            ]
          },
          {
            path:'qa',
            component: () => import('@/components/animate'),
            meta:{
              title:'tQA',
              requiresAuth: true,
              noCache: false,
              roles:[1,2]
            },
            children:[
              {
                path:'',
                component: () => import('@/views/qa'),
                name:'qa',
                meta:{
                  requiresAuth: true,
                  noCache: false,
                  roles:[1,2]
                },
              },
              {
                path:'detail',
                component: () => import('@/views/qa/detail'),
                name:'qaDetail',
                meta:{
                  title:'tQADetail',
                  requiresAuth: true,
                  noCache: false,
                  roles:[1,2]
                },
              },
              {
                path:'edit',
                component: () => import('@/views/qa/edit'),
                name:'qaEdit',
                meta:{
                  title:'tQAEdit',
                  requiresAuth: true,
                  noCache: false,
                  roles:[1,2]
                },
              },
            ]
          },
          {
            path:'wrong',
            component: () => import('@/components/animate'),
            meta:{
              title:'tWrong',
              requiresAuth: true,
              noCache: false,
              roles:[1,3]
            },
            children:[
              {
                path:'',
                component: () => import('@/views/wrong'),
                name:'wrong',
                meta:{
                  requiresAuth: true,
                  noCache: false,
                  roles:[1,3]
                },
              },
              {
                path:'question',
                component: () => import('@/views/wrong/question'),
                name:'question',
                meta:{
                  title:'tQuestion',
                  requiresAuth: true,
                  noCache: false,
                  roles:[1,3]
                },
              }
            ]
          }
        ]
      },
      {
        path: 'webpage',
        component: () => import('@/components/animate'),
        meta: {
          title:'tWebpage',
          requiresAuth: true,
          noCache: false,
          roles:[1,2]
        },
        redirect:'/webpage/carousel',
        children:[
          {
            path:'carousel',
            component: () => import('@/components/animate'),
            meta:{
              title:'tCarousel',
              requiresAuth: true,
              noCache: false,
              roles:[1,2]
            },
            children:[
              {
                path:'',
                component: () => import('@/views/carousel'),
                name:'carousel',
                meta:{
                  requiresAuth: true,
                  noCache: false,
                  roles:[1,2]
                },
              },
              {
                path:'edit',
                component: () => import('@/views/carousel/edit'),
                name:'carouselEdit',
                meta:{
                  title:'tCarouselEdit',
                  requiresAuth: true,
                  noCache: false,
                  roles:[1,2]
                },
              },
              {
                path:'detail',
                component: () => import('@/views/carousel/detail'),
                name:'carouselDetail',
                meta:{
                  title:'tCarouselDetail',
                  requiresAuth: true,
                  noCache: false,
                  roles:[1,2]
                },
              },
            ]
          },
          {
            path:'intro',
            component: () => import('@/components/animate'),
            meta:{
              title:'tIntro',
              requiresAuth: true,
              noCache: false,
              roles:[1,2]
            },
            children:[
              {
                path:'',
                component: () => import('@/views/intro'),
                name:'intro',
                meta:{
                  requiresAuth: true,
                  noCache: false,
                  roles:[1,2]
                },
              }
            ]
          },
          {
            path:'pass',
            component: () => import('@/components/animate'),
            meta:{
              title:'tPass',
              requiresAuth: true,
              noCache: false,
              roles:[1,2]
            },
            children:[
              {
                path:'',
                component: () => import('@/views/pass'),
                name:'pass',
                meta:{
                  requiresAuth: true,
                  noCache: false,
                  roles:[1,2]
                },
              },
              {
                path:'edit',
                component: () => import('@/views/pass/edit'),
                name:'passEdit',
                meta:{
                  title:'tPassEdit',
                  requiresAuth: true,
                  noCache: false,
                  roles:[1,2]
                },
              },
              {
                path:'detail',
                component: () => import('@/views/pass/detail'),
                name:'passDetail',
                meta:{
                  title:'tPassDetail',
                  requiresAuth: true,
                  noCache: false,
                  roles:[1,2]
                },
              },
            ]
          },
        ]
      },
      {
        path:'category',
        component: () => import('@/components/animate'),
        meta:{
          title:'tCategory',
          requiresAuth: true,
          noCache: false,
          roles:[1,3]
        },
        redirect:'/category/examType',
        children:[
          {
            path:'examType',
            component: () => import('@/components/animate'),
            meta:{
              title:'tExamType',
              requiresAuth: true,
              noCache: false,
              roles:[1,3]
            },
            children:[
              {
                path:'',
                component: () => import('@/views/examType'),
                name:'examType',
                meta:{
                  requiresAuth: true,
                  noCache: false,
                  roles:[1,3]
                },
              },
              {
                path:'edit',
                component: () => import('@/views/examType/edit'),
                name:'examTypeEdit',
                meta:{
                  title:'tExamTypeEdit',
                  requiresAuth: true,
                  noCache: false,
                  roles:[1,3]
                },
              }
            ]
          },
          {
            path:'subject',
            component: () => import('@/components/animate'),
            meta:{
              title:'tSubject',
              requiresAuth: true,
              noCache: false,
              roles:[1,3]
            },
            children:[
              {
                path:'',
                component: () => import('@/views/subject'),
                name:'subject',
                meta:{
                  requiresAuth: true,
                  noCache: false,
                  roles:[1,3]
                },
              },
              {
                path:'edit',
                component: () => import('@/views/subject/edit'),
                name:'subjectEdit',
                meta:{
                  title:'tSubjectEdit',
                  requiresAuth: true,
                  noCache: false,
                  roles:[1,3]
                },
              },
              {
                path:'detail',
                component: () => import('@/views/subject/detail'),
                name:'subjectDetail',
                meta:{
                  title:'tSubjectDetail',
                  requiresAuth: true,
                  noCache: false,
                  roles:[1,3]
                },
              },
              {
                path:':subjectId/chapter',
                component: () => import('@/components/animate'),
                meta:{
                  title:'tChapter',
                  requiresAuth: true,
                  noCache: false,
                  roles:[1,3]
                },
                children:[
                  {
                    path:'',
                    component: () => import('@/views/chapter'),
                    name:'chapter',
                    meta:{
                      requiresAuth: true,
                      noCache: false,
                      roles:[1,3]
                    },
                  },
                  {
                    path:'edit',
                    component: () => import('@/views/chapter/edit'),
                    name:'chapterEdit',
                    meta:{
                      title:'tChapterEdit',
                      requiresAuth: true,
                      noCache: false,
                      roles:[1,3]
                    },
                  },
                  {
                    path:':chapterId/section',
                    component: () => import('@/components/animate'),
                    meta:{
                      title:'',
                      requiresAuth: true,
                      noCache: false,
                      roles:[1,3]
                    },
                    children:[
                      {
                        path:'',
                        component: () => import('@/views/section'),
                        name:'sections',
                        meta:{
                          requiresAuth: true,
                          noCache: false,
                          roles:[1,3]
                        },
                      },
                      {
                        path:'edit',
                        component: () => import('@/views/section/edit'),
                        name:'sectionEdit',
                        meta:{
                          title:'tSectionEdit',
                          requiresAuth: true,
                          noCache: false,
                          roles:[1,3]
                        },
                      },
                      {
                        path:':sectionId/examination',
                        component: () => import('@/components/animate'),
                        meta:{
                          title:'tExamination',
                          requiresAuth: true,
                          noCache: false,
                          roles:[1,3]
                        },
                        children:[
                          {
                            path:'',
                            component: () => import('@/views/examination'),
                            name:'examination',
                            meta:{
                              requiresAuth: true,
                              noCache: false,
                              roles:[1,3]
                            },
                          },
                          {
                            path:'edit',
                            component: () => import('@/views/examination/edit'),
                            name:'examinationEdit',
                            meta:{
                              title:'tExaminationEdit',
                              requiresAuth: true,
                              noCache: false,
                              roles:[1,3]
                            },
                          },
                          {
                            path:'detail',
                            component: () => import('@/views/examination/detail'),
                            name:'examinationDetail',
                            meta:{
                              title:'tExaminationDetail',
                              requiresAuth: true,
                              noCache: false,
                              roles:[1,3]
                            },
                          },
                        ]
                      },
                    ]
                  },
                ]
              },
            ]
          }
        ]
      },
      {
        path: 'group',
        component: () => import('@/components/animate'),
        meta: {
          title:'tGroup',
          requiresAuth: true,
          noCache: false,
          roles:[1,2]
        },
        children:[
          {
            path:'',
            component: () => import('@/views/group'),
            name:'group',
            meta:{
              requiresAuth: true,
              noCache: false,
              roles:[1,2]
            },
          },
          {
            path:'detail',
            component: () => import('@/views/group/detail'),
            name:'groupDetail',
            meta:{
              title:'tGroupDetail',
              requiresAuth: true,
              noCache: false,
              roles:[1,2]
            }
          }
        ]
      },
      {
        path: 'order',
        component: () => import('@/components/animate'),
        meta: {
          title:'tOrder',
          requiresAuth: true,
          noCache: false,
          roles:[1,2]
        },
        children:[
          {
            path:'',
            component: () => import('@/views/order'),
            name:'order',
            meta:{
              requiresAuth: true,
              noCache: false,
              roles:[1,2]
            },
          },
          {
            path:'detail',
            component: () => import('@/views/order/detail'),
            name:'orderDetail',
            meta:{
              title:'tOrderDetail',
              requiresAuth: true,
              noCache: false,
              roles:[1,2]
            }
          }
        ]
      },
      {
        path: 'exam',
        component: () => import('@/components/animate'),
        redirect:'/exam/personal',
        meta: {
          title:'tExam',
          requiresAuth: true,
          noCache: false,
          roles:[1,3]
        },
        children:[
          {
            path:'personal',
            component: () => import('@/components/animate'),
            meta:{
              title:'tExamPersonal',
              requiresAuth: true,
              noCache: false,
              roles:[1,3]
            },
            children:[
              {
                path:'',
                component: () => import('@/views/exam/personal'),
                name:'examPersonal',
                meta:{
                  requiresAuth: true,
                  noCache: false,
                  roles:[1,3]
                }
              },
              {
                path:'detail',
                component: () => import('@/views/exam/personal/detail'),
                name:'examPersonalDetail',
                meta:{
                  title:'tExamPersonalDetail',
                  requiresAuth: true,
                  noCache: false,
                  roles:[1,3]
                }
              },
            ]
          },
          {
            path:'group',
            component: () => import('@/components/animate'),
            meta:{
              title:'tExamGroup',
              requiresAuth: true,
              noCache: false,
              roles:[1,3]
            },
            children:[
              {
                path:'',
                component: () => import('@/views/exam/group'),
                name:'examGroup',
                meta:{
                  requiresAuth: true,
                  noCache: false,
                  roles:[1,3]
                }
              },
              {
                path:'detail',
                component: () => import('@/views/exam/group/detail'),
                name:'examGroupDetail',
                meta:{
                  title:'tExamGroupDetail',
                  requiresAuth: true,
                  noCache: false,
                  roles:[1,3]
                }
              },
            ]
          }
        ]
      },
    ]
  },
]

// 静态路由
let defaultRouter = [{
  path: '/404',
  name: '404',
  component: () => import('@/views/404/index'),
   meta: {
      title: '404'
  }
},{
  path: '/login',
  name: 'login',
  component: () => import('@/views/login'),
  meta: {
    title:'tLogin'
  },
}]
export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: defaultRouter
})
