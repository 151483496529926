
import storage from '@/utils/storage.js'
const mutations = {
  saveUserInfo (state, data) {
    storage.setLocal('token',data.token)
    storage.setLocal('userinfo', data)
    state.adminUser = data
  },
  saveRoute (state, data) {
    state.routes = data
  },
  saveLanguage (state, data) {
    storage.setLocal('localeLaguage', data)
    state.selectLanguage = data.name
    state.elementLanguage = data.txt
  },
  addParams (state, data) {
    storage.setSession('params',[...state.paramsList].concat(data))
    state.paramsList = [...state.paramsList].concat(data)
  },
  delParams (state) {
    let list = [...state.paramsList]
    list.pop()
    storage.setSession('params',list)
    state.paramsList = list
  }
}
export default mutations
