/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import { router, routes } from './router'
import { filterAsyncRoutes } from './router/asyncRouter.js'
import '@/assets/css/reset.css'
import '@/assets/css/index.scss'
import store from './store'
import i18n from './i18n/i18n'
import storage from '@/utils/storage.js'
import initRules from '@/utils/initRules'
Vue.prototype.$initRules = initRules
import animated from 'animate.css'
Vue.use(animated)

// import NProgress from 'nprogress' // 进度条插件
// import 'nprogress/nprogress.css' // 进度条样式

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
ElementUI.Message.install = () => {
	Vue.prototype.$message = ElementUI.Message;
};
ElementUI.MessageBox.install = () => {
	Vue.prototype.$confirm = ElementUI.MessageBox.confirm;
};
ElementUI.Notification.install = () => {
	Vue.prototype.$notify = ElementUI.Notification;
};


// The same path err
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
router.afterEach(() => {
	window.scrollTo(0, 0)
})
const whiteList = ['/login']
router.beforeEach(async(to, from, next) => {
	(to.matched[to.matched.length - 1]?.path === to.matched[to.matched.length - 2]?.path + '/') && to.query ? to.matched[to.matched.length - 2].meta.query = to.query : 0
	// const type = to.meta.requiresAuth
	// if (type) {
	// 	if (storage.getLocal('userinfo') && storage.getLocal('userinfo').token) {
	// 		next()
	// 	} else {
	// 		storage.removeLocal('userinfo')
	// 		next('/login')
	// 	}
	// } else {
	// 	next()
	// }
	// 进度条开始
    // NProgress.start()
     // 获取路由 meta 中的title，并设置给页面标题
    // document.title = to.meta.title
    // 获取用户登录的token
    const hasToken = storage.getLocal('userinfo') && storage.getLocal('userinfo').token
    // 判断当前用户是否登录
    if (hasToken) {
        if (to.path === '/login') {
            next({ path: '/' })
            // NProgress.done()
        } else {
            // 从store中获取用户角色
            const hasRoles = store.state.routes && store.state.routes.length > 0  
            if (hasRoles) {
                next()
            } else {
                try {
                    // 获取用户角色
                    const roles = await store.state.adminUser.auth
                    // 通过用户角色，获取到角色路由表
                    const accessRoutes = filterAsyncRoutes(routes,[roles])
                    // 动态添加路由到router内
                    
                    router.matcher.addRoutes(accessRoutes)
                    store.commit('saveRoute',accessRoutes)
                    next({ ...to, replace: true })
                } catch (error) {
                    // 清除用户登录信息后，回跳到登录页去
                    storage.removeLocal('userinfo')
                    storage.removeLocal('token')
                    store.state.routes = null
                    next(`/login?redirect=${to.path}`)
                    // NProgress.done()
                }
            }
        }
    } else {
        // 用户未登录
        if (whiteList.indexOf(to.path) !== -1) {
            // 需要跳转的路由是否是whiteList中的路由，若是，则直接条状
            next()
        } else {
            // 需要跳转的路由不是whiteList中的路由，直接跳转到登录页
            next(`/login?redirect=${to.path}`)
            // 结束精度条
            // NProgress.done()
        }
    }
})
// router.afterEach(() => {
//     // 结束精度条
//     NProgress.done()
// })

Vue.config.productionTip = false

window.changeZH = function () {
	let obj = {
		name: "中文",
		txt: "zh",
		index: 1,
	};
	store.commit('saveLanguage',obj)
	location.reload();
}
window.changeKO = function () {
	let obj = {
		name: "한국어",
		txt: "ko",
		index: 0,
	};
	store.commit('saveLanguage',obj)
	location.reload();
}
export default new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
