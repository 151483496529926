import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
const zh = {
    tIndex: '首页',
    tMember:'会员列表',
    tMemberDetail:'会员详情',
    tScore:'会员积分',
    tToScore:'查看会员积分',
    tFinishHistory:'答题记录',
    tToHistory:'查看答题记录',
    tCustomerCenter:'客户中心',
    tNotice:'公告管理',
    tNoticeEdit:'编辑公告',
    tFAQ:'常见问题',
    tFAQEdit:'编辑常见问题',
    tQA:'问题与解答',
    tQADetail:'问题与解答详情',
    tServiceTerm:'服务条款',
    tAccount:'管理员列表',
    tAccountEdit:'添加管理员',
    tServiceTermEdit:'编辑条款',
    tWebpage:'网页管理',
    tCarousel:'轮播图管理',
    tCarouselEdit:'编辑轮播图',
    tCarouselDetail:'轮播图详情',
    tPass:'合格人员轮播图',
    tPassEdit:'编辑合格人员轮播图',
    tPassDetail:'合格人员轮播图详情',
    tIntro:'服务介绍管理',
    tIntroEdit:'编辑服务介绍',
    tCategory:'分类管理',
    tExamType:'考试类别清单管理',
    tExamTypeEdit:'编辑考试类别',
    tSubject:'科目清单',
    tSubjectEdit:'编辑科目',
    tSubjectDetail:'科目详情',
    tToChapter:'前往章节',
    tChapter:'章节列表',
    tChapterEdit:'编辑章节',
    tToSection:'前往小节',
    tSection:'小节列表',
    tSectionEdit:'编辑小节',
    tToExamination:'前往题目',
    tExamination:'题目列表',
    tExaminationEdit:'编辑题目',
    tExaminationDetail:'题目详情',
    tWrong:'错题举报',
    tQuestion:'回答',
    tGroup:'学习小组列表',
    tGroupDetail:'学习小组详情',
    tOrder:'订单管理',
    tOrderDetail:'订单详情',
    tExam:'解题管理',
    tExamPersonal:'个人解题列表',
    tExamPersonalDetail:'个人解题详情',
    tExamGroup:'小组解题列表',
    tExamGroupDetail:'小组解题详情',
    account:{
      active:'状态',
      active1:'激活',
      active0:'禁用',
      create_time:'创建时间',
      username:'用户名',
      password:'密码',
      changePassword:'修改密码',
      role:'用户权限',
      role1:'超级管理员',
      role2:'员工',
      role3:'保安'
    },
    memberManagement:{
      create_time:'创建时间',
      username:'用户名',
      real_name:'真实姓名',
      gender:'性别',
      gender0:'男',
      gender1:'女',
      gender2:'其他',
      phone:'联系方式',
      favorite_category:'喜欢的图书类别',
      favorite_category1:'警察(巡警)',
      favorite_category2:'警察(干部)',
      favorite_category3:'警察(晋升)',
      favorite_category4:'一般行政(9级)',
      favorite_category5:'一般行政(7级)',
      favorite_category6:'检查(9级)',
      favorite_category7:'检查(9级)',
      is_email:'是否同意接收邮件',
      is_sms:'是否同意接收SMS',
      active:'帐号状态',
      active0:'禁用',
      active1:'激活',
      yes:'是',
      no:'否',
      password:'密码',
      address:'地址',
      address_detail:'详细地址',
      email:'email',
      birth:'生日',
      passwordRule:'请至少输入八位数，包含英文数字特殊符号',
      member_information:'会员基本信息',
      self_hobby:'个人喜好',
      score:'积分'
  },
  finishHistory:{
    create_time:'创建时间',
    subject:'科目名称',
    chapter:'章节名称',
    exam_count:'题目总数',
    correct_count:'正确数量',
    correct_rate:'正确率',
    solved_count:'答题数量',
    solved_rate:'答题率',
    score:'分数'
  },
    serviceTerm:{
      title:'标题',
      content:'内容'
    },
    carouselManagement:{
      title:'标题',
      create_time:'创建时间',
      update_time:'更新时间',
      image:'图片',
      h5_image:'手机端图片',
      order:'排序值',
      link:'跳转链接',
      active:'是否显示',
      active0:'不显示',
      active1:'显示',
      sureDeleteCarousel:'确认删除轮播图',
      urlRule:'链接必须以http或https开头'
    },
    pass:{
      create_time:'创建时间',
      status:'状态',
      status0:'隐藏',
      status1:'显示',
      username:'用户名称',
      subject:'科目名称',
      row1:'第一行内容',
      row2:'第二行内容',
      row3:'第三行内容',
      size:'尺寸',
      size0:'普通',
      size1:'加粗',
      url:'跳转链接'
    },
    notice:{
      create_time:'创建时间',
      update_time:'更新时间',
      title:'标题',
      subtitle:'副标题',
      content:'内容',
      order:'排序值',
      active:'是否显示',
      active0:'不显示',
      active1:'显示',
      sureDeleteNotice:'确认删除公告',
      is_home:'是否推荐',
      is_home0:'不推荐',
      is_home1:'推荐',
      cover:'图片'
    },
    FAQ:{
      sureDeleteFAQ:'确认删除常见疑问',
      tag:'问题分类',
      order:'排序值',
      question:'问题',
      answer:'回答',
      active:'是否显示',
      active1:'显示',
      active0:'不显示'
    },
    QA:{
      create_time:'创建时间',
      update_time:'更新时间',
      title:'标题',
      question:'问题',
      status:'状态',
      status0:'未答复',
      status1:'已答复',
      answer:'回答',
      sureDeleteQA:'确认删除此反馈',
      username:'用户名',
      real_name:'真实姓名',
      category:'问题分类',
      category0:'创作与批评',
      category1:'bookclub',
      category2:'每日连载',
      category3:'活动',
      category4:'其他',
      is_top0:'取消置顶',
      is_top1:'置顶'
    },
    wrong:{
      title:'问题标题',
      subject:'科目名称',
      chapter:'章节名称',
      examination:'考试题目',
      user:'用户名称',
      category:'分类名称',
      status:'是否回复',
      status0:'未回复',
      status1:'已回复',
      active:'状态',
      active0:'隐藏',
      active1:'显示',
      content:'试题内容',
      create_time:'创建时间',
      update_time:'更新时间',
      toQuestion:'前往回答',
      answer:'回答',
      is_top0:'取消置顶',
      is_top1:'置顶'
    },
    examType:{
      title:'标题',
      status:'状态',
      status0:'禁用',
      status1:'启用',
      order:'排序',
      is_hot:'首页推荐',
      is_hot0:'不推荐',
      is_hot1:'推荐',
    },
    subject:{
      create_time:'创建时间',
      title:'标题',
      position:'考试类型',
      position_id:'考试类型',
      status:'状态',
      status0:'隐藏',
      status1:'显示',
      cover:'封面图',
      content_desc:'科目详情',
      chapter:'章节数',
      examination:'题目数',
      order:'排序',
      content:'科目选项',
      con_title:'标题',
      con_desc:'产品说明',
      con_service:'使用期限说明',
      con_price:'价格',
      con_days:'有效期',
      con_name:'选项名称',
      is_hot:'首页推荐',
      is_hot0:'不推荐',
      is_hot1:'推荐',
      price:'价格',
      time:'时间',
      sureCopy:'确定要复制此科目吗？',
      copy:'复制科目'
    },
    chapter:{
      create_time:'创建时间',
      title:'标题',
      status:'状态',
      status0:'禁用',
      status1:'启用'
    },
    section:{
      create_time:'创建时间',
      title:'标题',
      examination:'题目数量',
      status:'状态',
      status0:'禁用',
      status1:'启用'
    },
    examination:{
      create_time:'创建时间',
      title:'标题',
      type:'题目类型',
      type1:'对错题',
      type2:'简答题',
      type3:'对错题(数字类型)',
      type4:'简答题(数字类型)',
      level:'题目难度',
      level1:'难度1',
      level2:'难度2',
      level3:'难度3',
      content:'题目内容',
      explain:'解说',
      order:'题号',
      answer:'简答题答案',
      bool:'答案',
      bool0:'错误',
      bool1:'正确',
      status:'状态',
      status0:'禁用',
      status1:'启用',
      coding:'问题代码',
      result:'正确率',
      index:'题目号码',
      next:'下一题',
      previous:'上一题'
    },
    group:{
      user_username:'用户姓名',
      subject_name:'科目名称',
      chapter_name:'章节名称',
      timer:'做题秒数',
      name:'组名',
      type:'类型',
      type1:'随机间',
      type2:'团队',
      create_time:'创建时间',
      update_time:'更新时间',
    },
    score:{
      type:'类型',
      type1:'增加',
      type2:'减少',
      amount:'积分值',
      balance:'剩余积分',
      order_id:'订单id',
      create_time:'创建时间',
      update_time:'更新时间'
    },
    order:{
      title:'课程标题',
      user_username:'用户名',
      desc:'课程描述',
      service:'课程服务',
      subject_name:'科目名称',
      order_id:'订单id',
      price:'支付价格',
      paytime:'支付时间',
      begin_time:'开始时间',
      end_time:'结束时间',
      status:'状态',
      status1:'待支付',
      status2:'已支付',
      status3:'已到期',
      score:'已使用积分'
    },
    exam:{
      user_username:'用户名',
      exam_title:'题目',
      exam_content:'题目内容',
      exam_explain:'题目解说',
      exam_answer:'题目答案(填空题)',
      exam_bool:'题目答案(判断题)',
      exam_bool0:'错误',
      exam_bool1:'正确',
      answer:'用户答案(填空题)',
      boolean:'用户答案(判断题)',
      boolean0:'错误',
      boolean1:'正确',
      position_title:'考试类型名称',
      subject_title:'科目标题',
      chapter_title:'章节标题',
      is_bool:'是否正确',
      is_bool0:'错误',
      is_bool1:'正确',
      group_name:'组名',

      subject_info_title:'在听课程',
      progress_rate:'整体进度',
      correct_rate:'正确率',
      solved_rate_detail:'答题率',
      correct_rate_detail:'正确率',
    },


    sureDelete:'请再次确认删除',
    day1:'星期一',
    day2:'星期二',
    day3:'星期三',
    day4:'星期四',
    day5:'星期五',
    select_date:'选择日期',
    please_select:'请选择',
    start_date:'开始日期',
    end_date:'结束日期',
    to:'至',
    search: '请输入关键字',
    exit: '退出',
    in:'进入',
    backstageName: 'switch_admin',
    loginSuccess:'登录成功',
    signOutSuccess:'退出成功',
    changeSuccess:'修改成功',
    inputAccount: '请输入帐号名',
    inputPassword: '请输入密码',
    min6:'长度不少于6个字符',
    min3:'长度不少于3个字符',
    operation: '操作',
    search_zh: '搜索',
    back: '返回',
    tips:'提示',
    outExe:'导出excel',
    sort:'排序',
    all:'全部',
    btnTip: {
      submit: '确认',
      check: '查看',
      edit: '编辑',
      delete: '删除',
      add: '添加',
      close: '关闭',
      cancel:'取消',
      resetPW: '重置密码',
      refuse:'拒绝',
      reset:'重置'
    }
  }
export default {...zhLocale,...zh}
