import koLocale from 'element-ui/lib/locale/lang/ko'
const ko = {
    tIndex: '메인페이지',
    tMember:'회원 관리',
    tMemberDetail:'회원 디테일',
    tScore:'포인트',
    tToScore:'포인트 내역',
    tFinishHistory:'수강내역',
    tToHistory:'수강내역',
    tCustomerCenter:'고객센터',
    tNotice:'공지사항 관리',
    tNoticeEdit:'공지사항 편집',
    tFAQ:'자주묻는질문',
    tFAQEdit:'자주묻는질문 편집',
    tQA:'1:1문의',
    tQADetail:'1:1문의 디테일',
    tServiceTerm:'서비스약관 관리',
    tAccount:'관리자 관리',
    tAccountEdit:'관리자 추가',
    tServiceTermEdit:'이용약관 편집',
    tWebpage:'사이트 관리',
    tCarousel:'메인배너 관리',
    tCarouselEdit:'메인베너 편집',
    tCarouselDetail:'메인베너 디테일',
    tPass:'후기 관리',
    tPassEdit:'합격자베너 편집',
    tPassDetail:'합격자베너 디테일',
    tIntro:'서비스소개 관리',
    tIntroEdit:'서비스소개 편집',
    tCategory:'카테고리 관리',
    tExamType:'응시직렬 관리',
    tExamTypeEdit:'시험종류 편집',
    tSubject:'과목 관리',
    tSubjectEdit:'응시직렬 편집',
    tSubjectDetail:'응시직렬 디테일',
    tToChapter:'과목 관리',
    tChapter:'섹션 리스트',
    tChapterEdit:'과목 편집',
    tToSection:'섹션 관리',
    tSection:'섹션 리스트',
    tSectionEdit:'섹션 편집',
    tToExamination:'문제 관리',
    tExamination:'문제 리스트',
    tExaminationEdit:'문제 편집',
    tExaminationDetail:'문제 디테일',
    tWrong:'오답신고',
    tQuestion:'정답',
    tGroup:'스터디그룹',
    tGroupDetail:'스터디그룹 디테일',
    tOrder:'서비스 관리',
    tOrderDetail:'서비스 디테일',
    tExam:'문제풀이 관리',
    tExamPersonal:'개인문제풀이 관리',
    tExamPersonalDetail:'개인문제풀이 디테일',
    tExamGroup:'그룹문제풀이 관리',
    tExamGroupDetail:'그룹문제풀이 디테일',
    account:{
      active:'계정상태',
      active1:'사용중',
      active0:'탈퇴',
      create_time:'가입일자',
      username:'아이디',
      password:'비밀번호',
      changePassword:'비밀번호 수정',
      role:'관리자 관리',
      role1:'슈퍼관리자',
      role2:'직원',
      role3:'보안담당자'
    },
    memberManagement:{
      create_time:'가입일자',
      username:'아이디',
      real_name:'이름',
      gender:'성별',
      gender0:'남자',
      gender1:'여자',
      gender2:'기타',
      phone:'휴대전화번호',
      favorite_category:'관심 응시직렬',
      favorite_category1:'경찰(순경)',
      favorite_category2:'경찰(간부)',
      favorite_category3:'경찰(승진)',
      favorite_category4:'일반행정(9급)',
      favorite_category5:'일반행정(7급)',
      favorite_category6:'검찰(9급)',
      favorite_category7:'검찰(7급)',
      is_email:'이메일 수신동의',
      is_sms:'sms 수신동의',
      active:'계정 상태',
      active0:'탈퇴',
      active1:'사용중',
      yes:'네',
      no:'아니요',
      password:'비밀번호',
      email:'email',
      address:'주소',
      address_detail:'상세주소',
      birth:'생일',
      passwordRule:'영문, 숫자, 특수문자 포함 8자 이상 입력',
      member_information:'회원정보',
      self_hobby:'회원취향',
      score:'포인트'
  },
  finishHistory:{
    create_time:'등록시간',
    subject:'과목명',
    chapter:'섹션명',
    exam_count:'총 문제 ',
    correct_count:'맞춘 문제',
    correct_rate:'정답률',
    solved_count:'푼 문제',
    solved_rate:'진도율',
    score:'점수'
  },
    serviceTerm:{
      title:'제목',
      content:'내용'
    },
    carouselManagement:{
      title:'제목',
      create_time:'등록시간',
      update_time:'업데이트 시간',
      image:'사진',
      h5_image:'모바일버전 사진',
      order:'정렬순',
      link:'이동링크',
      active:'공개여부',
      active0:'비공개',
      active1:'공개',
      sureDeleteCarousel:'정말 삭제하시겠습니까? ',
      urlRule:' http/https로 시작된 링크여야 합니다.'
    },
    pass:{
      create_time:'등록시간',
      status:'상태',
      status0:'숨김',
      status1:'노출',
      username:'합격자이름',
      subject:'합격직위',
      row1:'후기 1줄',
      row2:'후기 2줄',
      row3:'후기 3줄',
      size:'사이즈',
      size0:'보통',
      size1:'볼드',
      url:'링크'
    },
    notice:{
      create_time:'등록시간',
      update_time:'업데이트 시간',
      title:'제목',
      subtitle:'부제',
      content:'내용',
      order:'정렬순',
      active:'공개여부',
      active0:'비공개',
      active1:'공개',
      sureDeleteNotice:'정말 삭제하시겠습니까?',
      is_home:'홈화면',
      is_home0:'일반',
      is_home1:'보통',
      cover:'사진'
    },
    FAQ:{
      sureDeleteFAQ:'정말 삭제하시겠습니까?',
      tag:'질문유형',
      order:'정렬순',
      question:'질문',
      answer:'답변',
      active:'공개여부',
      active1:'공개',
      active0:'비공개'
    },
    QA:{
      create_time:'등록시간',
      update_time:'업데이트 시간',
      title:'제목',
      question:'내용',
      status:'상태',
      status0:'답변대기',
      status1:'답변완료',
      answer:'답변',
      sureDeleteQA:'정말 삭제하시겠습니까?',
      username:'아이디',
      real_name:'이름',
      category:'질문유형',
      category0:'创作与批评',
      category1:'bookclub',
      category2:'每日连载',
      category3:'活动',
      category4:'其他',
      is_top0:'공지글 취소',
      is_top1:'공지글로 설정'
    },
    wrong:{
      title:'제목',
      subject:'과목명',
      chapter:'섹션명',
      examination:'문제번호',
      user:'아이디',
      category:'신고 종류',
      status:'상태',
      status0:'답변대기',
      status1:'답변완료',
      active:'공개여부',
      active0:'비공개',
      active1:'공개',
      content:'문제내용',
      create_time:'등록시간',
      update_time:'업데이트 시간',
      toQuestion:'답변하기',
      answer:'답변',
      is_top0:'공지글 취소',
      is_top1:'공지글로 설정'
    },
    examType:{
      title:'시험종류',
      status:'공개 여부',
      status0:'비공개',
      status1:'공개',
      order:'정렬순',
      is_hot:'홈화면',
      is_hot0:'보통',
      is_hot1:'홈화면',
    },
    subject:{
      create_time:'등록시간',
      title:'과목 이름',
      position:'응시직렬 이름',
      position_id:'응시직렬 이름',
      status:'공개여부',
      status0:'비공개',
      status1:'공개',
      cover:'썸네일',
      content_desc:'상세내용',
      chapter:'섹션',
      examination:'문제',
      order:'정렬순',
      content:'상품옵션',
      con_title:'옵션 번호',
      con_desc:'옵션 설명',
      con_service:'사용기간',
      con_price:'가격',
      con_days:'이용 가능 기간',
      con_name:'옵션 제목',
      is_hot:'홈화면',
      is_hot0:'보통',
      is_hot1:'홈화면',
      price:'노출가격',
      time:'노출옵션',
      sureCopy:'이 과목을 복사하시겠습니까?',
      copy:'과목 복사'
    },
    chapter:{
      create_time:'등록시간',
      title:'제목',
      status:'공개여부',
      status0:'비공개',
      status1:'공개'
    },
    section:{
      create_time:'등록시간',
      title:'제목',
      examination:'문제개수',
      status:'공개여부',
      status0:'비공개',
      status1:'공개'
    },
    examination:{
      create_time:'등록시간',
      title:'제목',
      type:'문제유형',
      type1:'O/X형',
      type2:'단답형',
      type3:'숫자형 O/X',
      type4:'숫자형 단답',
      level:'난이도',
      level1:'STEP 1',
      level2:'STEP 2',
      level3:'STEP 3',
      content:'문제내용',
      explain:'설명',
      order:'문제번호',
      answer:'단답형 답안',
      bool:'정답',
      bool0:'틀림',
      bool1:'맞음',
      status:'공개여부',
      status0:'비공개',
      status1:'공개',
      coding:'문제코드',
      result:'총 정답율',
      index:'문제색인',
      next:'다음 문제',
      previous:'이전 문제'
    },
    group:{
      user_username:'이름',
      subject_name:'과목명',
      chapter_name:'섹션명',
      timer:'시간',
      name:'방이름',
      type:'종류',
      type1:'랜덤방',
      type2:'그룹방',
      create_time:'등록시간',
      update_time:'업데이트 시간',
    },
    score:{
      type:'종류',
      type1:'추가',
      type2:'제거',
      amount:'포인트',
      balance:'사용가능 포인트',
      order_id:'결제내역',
      create_time:'등록시간',
      update_time:'업데이트 시간'
    },
    order:{
      title:'수강과목 ',
      user_username:'아이디',
      desc:'과목설명',
      service:'수강과목',
      subject_name:'결제과목',
      order_id:'결제내역',
      price:'결제금액',
      paytime:'결제시간',
      begin_time:'시작시간',
      end_time:'종료시간',
      status:'공개여부',
      status1:'결제대기',
      status2:'결제완료',
      status3:'기한만료',
      score:'사용 포인트'
    },
    exam:{
      user_username:'사용자 아이디',
      exam_title:'문제',
      exam_content:'문제내용',
      exam_explain:'문제설명',
      exam_answer:'문제정답(단답형)',
      exam_bool:'문제정답(판단문제)',
      exam_bool0:'틀림',
      exam_bool1:'맞음',
      answer:'사용자정답(단답형)',
      boolean:'사용자정답(판단문제)',
      boolean0:'틀림',
      boolean1:'맞음',
      position_title:'응시직렬',
      subject_title:'과목명',
      chapter_title:'섹션명',
      is_bool:'정확여부',
      is_bool0:'틀림',
      is_bool1:'맞음',
      group_name:'방이름',
      
      subject_info_title:'수강과목',
      progress_rate:'전체 진도',
      correct_rate:'정답율',
      solved_rate_detail:'푼 문제',
      correct_rate_detail:'맞춘 문제',
    },


    sureDelete:'정말 삭제하시겠습니까?',
    day1:'월요일',
    day2:'화요일',
    day3:'수요일',
    day4:'목요일',
    day5:'금요일',
    select_date:'날짜선택',
    please_select:'선택',
    start_date:'시작시간',
    end_date:'종료시간',
    to:'-',
    search: '검색내용을 입력해보세요. ',
    exit: '로그아웃',
    in:'로그인',
    backstageName: 'ttedu_admin',
    loginSuccess:'로그인 성공하였습니다.',
    signOutSuccess:'로그아웃 되었습니다',
    changeSuccess:'수정 성공하였습니다.',
    inputAccount: '아이디를 입력해주세요',
    inputPassword: '비밀번호를 ',
    min6:'6자 이상 입력',
    min3:'3자 이상 입력',
    operation: '관리',
    search_zh: '검색',
    back: 'Back',
    tips:'알람',
    outExe:'excel 출력',
    sort:'정렬순',
    all:'전체',
    btnTip: {
      submit: '확인',
      check: '조회',
      edit: '수정',
      delete: '삭제',
      add: '추가',
      close: '닫기',
      cancel:'취소',
      resetPW: '비번 설정',
      refuse:'거절',
      reset:'리셋'
    }
  }
export default {...ko,...koLocale}
