export default {
	getSession: function (name) {
		if (!name) {
			return
		}
		return JSON.parse(window.sessionStorage.getItem(name))
	},
	setSession: function (name, context) {
		if (!name) {
			return
		}
		if (context !== 'String') {
			context = JSON.stringify(context)
		}
		window.sessionStorage.setItem(name, context)
	},
	removeSession: function (name) {
		if (!name) {
			return
		}
		return window.sessionStorage.removeItem(name)
	},
	getLocal: function (name) {
		if (!name) {
			return
		}
		return JSON.parse(window.localStorage.getItem(name))
	},
	setLocal: function (name, context) {
		if (!name) {
			return
		}
		if (context !== 'String') {
			context = JSON.stringify(context)
		}
		window.localStorage.setItem(name, context)
	},
	removeLocal: function (name) {
		if (!name) {
			return
		}
		return window.localStorage.removeItem(name)
	}
}
